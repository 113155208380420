import { HTMLAttributes, FC } from 'react'
import { FieldByType } from '@/components/Input/FieldByType'
import { css } from '@emotion/react'
import { useFormContext, FieldValues, useFormState, get } from 'react-hook-form'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  wrapper: css({
    position: 'relative',
    border: `2px solid ${palette.gray4}`,
    borderRadius: 4,
    backgroundColor: palette.white,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 6px)',
      right: 16,
      width: 6,
      height: 6,
      borderBottom: `2px solid ${palette.gray4}`,
      borderRight: `2px solid ${palette.gray4}`,
      transform: 'rotate(45deg)',
      pointerEvents: 'none',
      [mq('max', 320)]: {
        right: 7
      }
    }
  }),
  select: css({
    width: '100%',
    padding: '12px 8px',
    appearance: 'none',
    background: 'none',
    cursor: 'pointer'
  }),
  error: css({
    backgroundColor: 'rgba(225, 57, 77, 0.1)'
  })
}

export type SelectValues = { label: string; value: string }[]

export type SelectProps<T extends FieldValues> = {
  name: FieldByType<T, string> | FieldByType<T, string | undefined>
  values: SelectValues
  defaultOption?: string
} & Omit<HTMLAttributes<HTMLSelectElement>, 'name'>

export const Select = <T extends FieldValues = never>({
  name,
  defaultOption,
  defaultValue,
  values,
  ...props
}: SelectProps<T>): ReturnType<FC> => {
  const { register } = useFormContext()
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <div css={styles.wrapper}>
      <select
        css={[styles.select, error && styles.error]}
        {...register(name)}
        {...props}
        defaultValue={defaultValue || ''}
      >
        {defaultOption && (
          <option disabled value="">
            {defaultOption}
          </option>
        )}
        {values.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}
