// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Crm {
  export const areaRequest = 'Crm::AreaRequest'
  export const cemeteryRequest = 'Crm::CemeteryRequest'
  export const gravestoneRequest = 'Crm::GravestoneRequest'
  export const hakajimaiRequest = 'Crm::HakajimaiRequest'
  export const tourReservation = 'Crm::TourReservation'
}

export const areaRequestServiceId = '11069'
export const gravestoneRequestServiceId = '11463'
export const hakajimaiRequestServiceId = '11483'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Purpose {
  export const both = 'both'
  export const tour = 'tour'
  export const brochure = 'brochure'
}
