import { FC } from 'react'
import { useFormState, FieldValues, Path } from 'react-hook-form'
import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  message: css({
    color: palette.alert,
    fontWeight: 'bold'
  })
}

interface ErrorMessageProps<T> {
  name: Path<T>
}

export const ErrorMessage = <T extends FieldValues = never>({
  name
}: ErrorMessageProps<T>): ReturnType<FC> => {
  const { errors } = useFormState()
  return <RHFErrorMessage errors={errors} name={name} render={RenderMessage} />
}

interface RenderMessageProps {
  message: string
}

const RenderMessage: FC<RenderMessageProps> = ({ message }) => {
  return <p css={[typography.textS, styles.message]}>{message}</p>
}
