import { useFormStoreSubmit } from 'next-runtime/form/store'
import { useRouter } from 'next/router'
import {
  FieldValues,
  FieldPath,
  useFormContext,
  UnpackNestedValue
} from 'react-hook-form'
import { useMemo } from 'react'

export const useSubmitCombineWithHookForm = <T extends FieldValues, U>({
  dataConvertFunction,
  onSuccess
}: {
  dataConvertFunction?: (data: UnpackNestedValue<T>) => object
  onSuccess?: (state: U) => void
} = {}) => {
  const { setError, handleSubmit } = useFormContext<T>()
  const submit = useFormStoreSubmit() // このsubmit関数がnext-runtimeとの通信および、そのレスポンスを受けて何をするかをいい感じにコントロールしてくれる

  const router = useRouter()

  return useMemo(() => {
    return handleSubmit(async (data, event) => {
      const formData = jsonToFormData(dataConvertFunction?.(data) ?? data)

      await submit({
        router,
        method: event?.target?.method ?? 'post',
        formAction: event?.target?.action ?? location.href,
        formData,
        onSuccess: (res) => {
          // onSuccessだが、postがjsonでエラーメッセージを返してくる場合にここを通る。redirectを返した場合は、ここを通る前にリダイレクトしてくれる
          const { data } = res
          if (hasErrors<T>(data)) {
            setError('badrequest' as keyof typeof data.errors, {
              message: '入力内容を確認してもう一度お試しください'
            })
          } else {
            onSuccess?.(data as U)
          }
        },
        onError: (res) => {
          // Nextjsのバックエンドサーバーが死んでるとかじゃなければここは通らない
          console.error(res)
          throw new Error(`Occurred Unexpected Error: ${res.error?.message}`)
        }
      })
    })
  }, [handleSubmit, dataConvertFunction, submit, router, setError, onSuccess])
}

const hasErrors = <T extends FieldValues>(
  data: unknown
): data is { errors: Record<FieldPath<T>, string> } =>
  typeof data === 'object' && data !== null && 'errors' in data

const buildFormData = ({
  formData,
  data,
  parentKey = ''
}: {
  formData: FormData
  data: object
  parentKey?: string
}) => {
  if (data && typeof data === 'object') {
    Object.entries(data).forEach(([key, value]) => {
      buildFormData({
        formData,
        data: value,
        parentKey: parentKey ? `${parentKey}[${key}]` : key
      })
    })
  } else {
    formData.append(parentKey, data ?? '')
  }
}

const jsonToFormData = (data: object): FormData => {
  const formData = new FormData()

  buildFormData({ formData, data })

  return formData
}
