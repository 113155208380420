import { forwardRef, InputHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  checkbox: css({
    position: 'relative',
    display: 'inline-block',
    width: 20,
    height: 20,
    flexShrink: 0,
    border: `2px solid ${palette.gray4}`,
    borderRadius: 2,
    boxSizing: 'content-box',
    backgroundColor: palette.white,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 5,
      top: 0,
      width: 6,
      height: 12,
      borderRight: `4px solid ${palette.gray2}`,
      borderBottom: `4px solid ${palette.gray2}`,
      transform: 'rotate(45deg)'
    }
  }),
  input: css({
    opacity: 0,
    position: 'absolute',
    left: 0,
    ':checked + span::before': {
      borderRight: `4px solid ${palette.accent}`,
      borderBottom: `4px solid ${palette.accent}`
    },
    ':focus-visible + span': {
      outline: '2px auto -webkit-focus-ring-color'
    }
  })
}

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement>

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    return (
      <>
        <input type="checkbox" css={styles.input} {...props} ref={ref} />
        <span css={styles.checkbox} />
      </>
    )
  }
)
