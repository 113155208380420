import { useRef } from 'react'
import { eventPush } from '@lifedot/tracking'
import type { TrackingCategoryType } from '@/constants/TrackingCategories'

export const useCvTracking = ({
  category,
  label
}: {
  category: TrackingCategoryType
  label: string
}) => {
  const eventSend = useRef(false)
  return () => {
    if (!eventSend.current) {
      eventPush({ category, action: 'submit', label })
      eventSend.current = true
    }
  }
}
