import { FC } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useForm,
  FormProvider,
  DefaultValues,
  FieldValues,
  useFormContext,
  UnpackNestedValue
} from 'react-hook-form'
import { useSubmitCombineWithHookForm } from '@/hooks/use-submit-combine-with-hook-form/use-submit-combine-with-hook-form'
import { Beforeunload } from 'react-beforeunload'
import { Progress } from '@lifedot/components/Progress'

interface RequestFormProps<T, U> {
  schema: Yup.AnyObjectSchema
  defaultValues?: DefaultValues<T>
  children?: React.ReactNode
  onSuccess?: (props: U) => void
  dataConvertFunction?: (data: UnpackNestedValue<T>) => object
}

export const RequestForm = <T extends FieldValues = never, U = never>({
  schema,
  children,
  defaultValues,
  onSuccess,
  dataConvertFunction
}: RequestFormProps<T, U>): ReturnType<FC> => {
  const methods = useForm<T>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // TODO: react-hook-form v8形で解消予定
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues
  })
  return (
    <FormProvider {...methods}>
      <InnerForm<T, U>
        onSuccess={onSuccess}
        dataConvertFunction={dataConvertFunction}
      >
        {children}
      </InnerForm>
    </FormProvider>
  )
}

type InnerFormProps<T, U> = {
  onSuccess?: (props: U) => void
  dataConvertFunction?: (data: UnpackNestedValue<T>) => object
  children?: React.ReactNode
}

const InnerForm = <T extends FieldValues, U>({
  children,
  onSuccess,
  dataConvertFunction
}: InnerFormProps<T, U>): ReturnType<React.FC> => {
  const onSubmit = useSubmitCombineWithHookForm<T, U>({
    onSuccess,
    dataConvertFunction
  })
  const {
    formState: { touchedFields, isSubmitting }
  } = useFormContext()

  return (
    <Beforeunload
      onBeforeunload={() =>
        Object.keys(touchedFields).length && !isSubmitting
          ? '入力された情報は保存されません。ページを移動しますか？'
          : undefined
      }
    >
      {isSubmitting && <Progress />}
      <form method="post" onSubmit={onSubmit}>
        {children}
      </form>
    </Beforeunload>
  )
}
